/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Menu class toggle
        $('.wrapper-menu, .menu').click(function(e){
          $('.wrapper-menu').toggleClass('open');
          $('body').toggleClass('menu-open');
          $('body').toggleClass('menu-closed');
          $('.menu-icon').toggleClass('menu-sm');
        });

        // Menu class toggle
        $('.nav-wrapper').click(function(e){
          $('.wrapper-menu').toggleClass('open');
          $('body').toggleClass('menu-open');
          $('body').toggleClass('menu-closed');
          $('.menu-icon').toggleClass('menu-sm');
        });

        var swiperExists = document.getElementsByClassName('post-slider');
        if (swiperExists.length >= 1) {
          var postSwiper = new Swiper('.post-slider', {
            speed: 600,
            autoplay: $('.swiper-slide').length > 1 ? true : false,
            loop: $('.swiper-slide').length > 1 ? true : false,
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 30,
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            grabCursor: true,
            fadeEffect: {
              crossFade: true,
            },
          });
        }

        console.log($(".post-slider .swiper-slide").length);

        if ( $(".post-slider .swiper-slide").length == 1 ) {
        }

        var swiperExists = document.getElementsByClassName('artist-slider');
        if (swiperExists.length >= 1) {
          var artistSwiper = new Swiper('.artist-slider', {
            speed: 600,
            autoplay: $('.swiper-slide').length > 1 ? true : false,
            loop: $('.swiper-slide').length > 1 ? true : false,
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 30,
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            grabCursor: true,
            fadeEffect: {
              crossFade: true,
            },
          });
        }

        var swiperExists = document.getElementsByClassName('gallery-swiper-container');
        if (swiperExists.length >= 1) {
          var gallerySwiper = new Swiper('.gallery-swiper-container', {
            speed: 600,
            autoplay: $('.swiper-slide').length > 1 ? true : false,
            loop: $('.swiper-slide').length > 1 ? true : false,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            grabCursor: true,
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            }
          });
        }

        $('.animate').each(function() {
          var inviewAnimate = new Waypoint.Inview({
            element: this,
            enter: function(direction) {
              // notify('Enter triggered with direction ' + direction)
              $(this.element).addClass('animated');
              $(this.element).addClass('fadeInUp');
            },
            entered: function(direction) {
            },
            exit: function(direction) {
              // notify('Exit triggered with direction ' + direction)
            },
            exited: function(direction) {
              // $(this.element).removeClass('animated');
              // $(this.element).removeClass('fadeInUp');
            }
          });
        });

        $(function () {
             var $win = $(window);

             $win.scroll(function () {
                 if ($win.scrollTop()< 50) {
                   $('.menu-icon').removeClass('menu-sm');
                 }
                 else {
                   $('.menu-icon').addClass('menu-sm');
                 }
             });
         });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var inviewLogo = new Waypoint.Inview({
            element: $('.logo')[0],
            enter: function(direction) {
              $('.logo').removeClass('hide');
              $('.brand').removeClass('show');
            },
            entered: function(direction) {
            },
            exit: function(direction) {
              $('.logo').addClass('hide');
              $('.brand').addClass('show');
            },
            exited: function(direction) {
            }
          });

        // var inviewContact = new Waypoint.Inview({
        //     element: $('.logo')[0],
        //     enter: function(direction) {
        //       // $('.footer-contact').removeClass('show');
        //       $('.menu-icon').removeClass('menu-sm');
        //     },
        //     exit: function(direction) {
        //       // $('.footer-contact').addClass('show');
        //       $('.menu-icon').addClass('menu-sm');
        //     }
        //   });

        var inviewScene0 = new Waypoint.Inview({
            element: $('#scene0')[0],
            enter: function(direction) {
              var slide = $(".swiper-slide-active");

              if (slide.hasClass('video_option')) {

                var $frame = slide.find('.video-bg');

                var vidsrc = slide.attr('data-video-src');

                // sets it back to the correct link so that it reloads immediately on the next window open
                $frame.attr('src', vidsrc);

              }
            },
            exited: function(direction) {
              var slide = $(".swiper-slide-active");

              if (slide.hasClass('video_option')) {

                var $frame = slide.find('.video-bg');

                // sets the source to nothing, stopping the video
                $frame.attr('src', '');

              }
            },
          });

        var home_swiper = new Swiper('.home-hero-swiper-container', {
          speed: 1000,
          autoplay: $('.swiper-slide').length > 1 ? true : false,
          loop: $('.swiper-slide').length > 1 ? true : false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          grabCursor: true,
          fadeEffect: {
            crossFade: true,
          },
          on: {
            init: function () {
              $('.home-animate', '.swiper-slide-active').addClass('animated fadeInDownBig');
              // $('.home-animate', 'swiper-slide-active').toggleClass('home-animate');
            },
            transitionStart: function () {
              $('.swiper-slide', Swiper.$wrapperEl).addClass('changing');
              $('.swiper-slide .row', Swiper.$wrapperEl).addClass('changing');
            },
            transitionEnd: function () {
              $('.swiper-slide', Swiper.$wrapperEl).removeClass('changing');
              $('.swiper-slide .row', Swiper.$wrapperEl).removeClass('changing');
              $('.home-animate', Swiper.$wrapperEl).addClass('animated fadeInDownBig');
              $('.home-animate', '.swiper-slide-prev').removeClass('animated fadeInDownBig');
              $('.home-animate', '.swiper-slide-next').removeClass('animated fadeInDownBig');

              var slide = $(".swiper-slide-active");

              if (slide.hasClass('video_option')) {

                var $frame = slide.find('.video-bg');

                var vidsrc = slide.attr('data-video-src');

                // sets it back to the correct link so that it reloads immediately on the next window open
                $frame.attr('src', vidsrc);

              }

              if ($('.swiper-slide-prev').hasClass('video_option')) {

                var $frame = $('.swiper-slide-prev').find('.video-bg');

                // sets the source to nothing, stopping the video
                $frame.attr('src',''); 

              }

              if ($('.swiper-slide-next').hasClass('video_option')) {

                var $frame = $('.swiper-slide-next').find('.video-bg');

                // sets the source to nothing, stopping the video
                $frame.attr('src',''); 

              }
            },
          }
        });

        $('.home-hero-slide').each(function(){
          var $this = $(this);
          var $bgImg = $this.attr('data-bg');
          $('.row', this).css('background-image', 'url('+$bgImg+')');

          if ($this.hasClass('video_option') && !$this.hasClass('swiper-slide-active')) {

            var $frame = $this.find('.video-bg');

            // sets the source to nothing, stopping the video
            $frame.attr('src',''); 

          }

        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Artist page
    'single_artists': {
      init: function() {
        // JavaScript to be fired on the single artist page
        minFontSize = 2.25/*vw*/;
        maxFontSize = 6/*vw*/;
        $('.artist-gallery-title').each(function(){
          var t          = $(this);
          var width      = t.data("width") * 2;
          var characters = t.html().length;
          var size       = width/characters;
        
          if(size > maxFontSize) {
            size = maxFontSize;
          }
          else if(size < minFontSize) {
            size = minFontSize;
          }
          
          $(this).css('font-size', size+'vw');
        });
      }
    },
    // Exhibit page
    'single_exhibitions': {
      init: function() {
        // JavaScript to be fired on the single artist page
        minFontSize = 8/*vw*/;
        maxFontSize = 20/*vw*/;
        $('.slide__name').each(function(){
          var t          = $(this);
          var width      = t.data("width") * 2;
          var characters = t.html().length;
          var size       = width/characters;
        
          if(size > maxFontSize) {
            size = maxFontSize;
          }
          else if(size < minFontSize) {
            size = minFontSize;
          }
          
          $(this).css('font-size', size+'vw');
        });
      }
    },
    // Exhibitions page
    'post_type_archive_exhibitions': {
      init: function() {
        // JavaScript to be fired on the exhbitions page

        // Current exhibitions Swiper
        var current_exhibits_swiper = new Swiper('.exhibit-hero-swiper-container', {
          speed: 1000,
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
          },
          loop: false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          fadeEffect: {
            crossFade: true,
          },
          on: {
            init: function () {
              $('.exhibit-animate', '.swiper-slide-active').addClass('animated fadeInDownBig');
              // $('.exhibit-animate', 'swiper-slide-active').toggleClass('exhibit-animate');
            },
            transitionStart: function () {
              $('.swiper-slide', Swiper.$wrapperEl).addClass('changing');
              $('.swiper-slide .row', Swiper.$wrapperEl).addClass('changing');
            },
            transitionEnd: function () {
              $('.swiper-slide', Swiper.$wrapperEl).removeClass('changing');
              $('.swiper-slide .row', Swiper.$wrapperEl).removeClass('changing');
              $('.exhibit-animate', Swiper.$wrapperEl).addClass('animated fadeInDownBig');
              $('.exhibit-animate', '.swiper-slide-prev').removeClass('animated fadeInDownBig');
              $('.exhibit-animate', '.swiper-slide-next').removeClass('animated fadeInDownBig');
            },
          }
        });

        // Upcoming exhibitions Swiper
        var upcoming_exhibits_swiper = new Swiper('.exhibit-upcoming-swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 30,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        });

        // Past exhibitions Isotope Layout with category sorting

        // init Isotope
        var $container = $('.isotope').isotope({
          itemSelector: '.item',
          layoutMode: 'fitRows',
          
        });
          
          // filter functions
        var filterFns = {
          // show if number is greater than 50
          numberGreaterThan50: function() {
            var number = $(this).find('.number').text();
            return parseInt( number, 10 ) > 50;
          },
          // show if name ends with -ium
          ium: function() {
            var name = $(this).find('.name').text();
            return name.match( /ium$/ );
          }
        };
        // bind filter button click
        $('#filters').on( 'click', 'button', function() {
          var filterValue = $( this ).attr('data-filter');
          // use filterFn if matches value
          filterValue = filterFns[ filterValue ] || filterValue;
          $container.isotope({ filter: filterValue });
        });
        // change is-checked class on buttons
        $('.button-group').each( function( i, buttonGroup ) {
          var $buttonGroup = $( buttonGroup );
          $buttonGroup.on( 'click', 'button', function() {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $( this ).addClass('is-checked');
          });
        });

        //****************************
        // Isotope Load more button
        //****************************
        var initShow = 6; //number of items loaded on init & onclick load more button
        var counter = initShow; //counter for load more button
        var iso = $container.data('isotope'); // get Isotope instance

        console.log($container);

        loadMore(initShow); //execute function onload

        function loadMore(toShow) {
          $container.find(".hidden").removeClass("hidden");

          var hiddenElems = iso.filteredItems.slice(toShow, iso.filteredItems.length).map(function(item) {
            return item.element;
          });
          $(hiddenElems).addClass('hidden');
          $container.isotope('layout');

          //when no more to load, hide show more button
          if (hiddenElems.length == 0) {
            jQuery("#load-more").hide();
          } else {
            jQuery("#load-more").show();
          };

        }

        //append load more button
        $container.after('<button id="load-more" class="btn btn-primary btn-lg"> Load More</button>');

        //when load more button clicked
        $("#load-more").click(function() {
          if ($('#filters').data('clicked')) {
            //when filter button clicked, set initial value for counter
            counter = initShow;
            $('#filters').data('clicked', false);
          } else {
            counter = counter;
          };

          counter = counter + initShow;

          loadMore(counter);
        });

        //when filter button clicked
        $("#filters").click(function() {
          $(this).data('clicked', true);

          loadMore(initShow);
        });

      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
